<template>
    <div>
        <div>
            <v-text-field hide-details dense clearable outlined v-model="filters.word" :label="$t('base.search')"
                          prepend-inner-icon="mdi-magnify" class="mb-1" />
            <v-row>
                <v-col cols="12" sm="6">
                    <date-chooser clearable v-model="filters.from" :label="$t('log.filter_from')"></date-chooser>
                </v-col>
                <v-col cols="12" sm="6">
                    <date-chooser clearable v-model="filters.to"   :label="$t('log.filter_to')"></date-chooser>
                </v-col>
            </v-row>
        </div>
        <v-data-table
                dense
                class="elevation-2"
                :headers="headers"
                :items="items"
                :options.sync="options"
                :server-items-length="total"
                sort-by="created"
                :sort-desc="true"
                :footer-props="{'items-per-page-options': [20,50,100,200,500]}"
                :loading="loading"
        >
            <template v-slot:item.created="{item}">
                <strong>{{item.created}}</strong>
            </template>
            <template v-slot:item.user.login="{item}">
                <strong class="red--text">{{item.user.admin ? 'ADMIN' : item.user.login}}</strong>
            </template>
            <template v-slot:item.account="{item}">
                <strong class="orange--text" v-if="item.account">{{item.account.login}}</strong>
            </template>
        </v-data-table>
    </div>
</template>

<script>

    import DateChooser from "../chooser/DateChooser";
    import UserService from "../../service/api/userService";
    export default {
        name: "user-log",
        components: {DateChooser},
        props: {
            handler : {
                type : Function
            }
        },
      computed: {
      },
        mounted() {
            this.getDataFromApi()
        },
        watch: {
            filters: {
                handler () {
                    this.getDataFromApi()
                },
                deep: true,
            },
            options: {
                handler () {
                    this.getDataFromApi()
                },
                deep: true,
            },
        },
        data() {
            return {
                filters : {
                    word : "",
                    from : null,
                    to : null,
                },
                loading: false,
                headers : [
                    {value : 'created',text : this.$t('base.time')},
                    {value : 'user.login',text : this.$t('base.user')},
                    {value : 'account',text : this.$t('base.account')},
                    {value : 'description',text : this.$t('base.desc')},
                ],
                items : [],
                total : 0,
                options: {},
            }
        },
        methods: {
            getDataFromApi() {

                const { sortBy, descending, page, itemsPerPage } = this.options
                this.loading = true
                this.handler(this.options,this.filters)
                    .then(res => {
                        this.items = res.data.content
                        this.total  = res.data.totalElements
                        //this.options.page = res.pageNumber
                    })
                    .catch(err => {
                        this.$swal(this.$t('errors.error'),'','error')
                    })
                    .finally(() => this.loading = false)
            }
        },
    }
</script>

<style scoped>

</style>