<script>

export default {
  name: "SubscriptionWidget",
  props: {
    packet: {
      type: Object,
    },
    light : {
      type : Boolean,
      default : false,
    },
    dark : {
      type : Boolean,
      default : false,
    },
    color : {
      type : String,
      default : ""
    }
  },
}
</script>

<template>
  <v-card :light="light" :dark="dark" elevation="15" :color="color ? color : ''">
    <v-card-title>
      {{packet.subscription.name}}
      &nbsp;<v-chip dark>{{packet.filter.connection}} {{$t('packet.connections')}}</v-chip>
      <v-spacer></v-spacer>
      <v-icon v-if="packet.paused">mdi-pause</v-icon>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <v-img class="text-center" contain width="100" height="100"
                 :src="packet.subscription.logoUrl"></v-img>
        </v-col>
        <v-col cols="8">
          <div>
            <div>{{$t('packet.from')}}</div>
            <v-chip class="pa-2" label>
              <strong>{{packet.from}}</strong>
            </v-chip>
          </div>
          <div>
            <template v-if="packet.paused">
              <div>{{$t('packet.pause_time')}}</div>
              <v-chip class="pa-2" label outlined>
                <strong >{{packet.lastPausedTime}}</strong>
              </v-chip>
            </template>
            <template v-else>
              <div>{{$t('packet.to')}}</div>
              <v-chip class="pa-2" label>
                <strong>{{packet.to}}</strong>
              </v-chip>
            </template>
          </div>
        </v-col>
      </v-row>
      <div>
      </div>
    </v-card-text>
  </v-card>
</template>

<style scoped>

</style>