<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" v-if="packets" v-for="packet in packets">
          <subscription-widget :packet="packet" light/>
        </v-col>
        <v-col cols="12" sm="6" v-if="hasOnlyFree" v-for="packet in pricelistPackets">
          <user-price-item @onBuy="getUserPackets" :account-id="accountId" :item="packet"/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import packetService from "../../service/api/packetService";
import {EventBus} from "@/service/eventBus";
import SubscriptionWidget from "@/components/packet/SubscriptionWidget.vue";
import UserPriceItem from "@/components/packet/UserPriceItemEx.vue";

export default {
  name: "user-packets-list",
  components: {UserPriceItem, SubscriptionWidget},
  props: {
    accountId: {
      type: String
    },
  },
  created() {
    this.getUserPackets()
    this.getPriceList(this.accountId)
  },
  computed: {
    hasOnlyFree() {
      return this.packets && this.packets.length === 1 && this.packets[0].subscription.availableFree
    }
  },
  data() {
    return {
      loading: false,
      packets: [],
      pricelistPackets: [],
    }
  },
  methods: {
    togglePause(packetId) {
      this.$swal.fire({
        title: this.$t('base.sure'),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('base.cancel'),
        confirmButtonText: this.$t('base.confirm')
      }).then((result) => {
        if (result.value) {
          this.loading = true
          packetService.togglePlayPause(packetId).then(res => {
            this.$swal(this.$t('base.success'), this.$t('base.done'), 'success')
            this.getUserPackets()
          }).catch(error => {
            this.$swal.fire(this.$t('errors.error'), this.$t(`errors.${error.response.data.code}`), 'error')
          }).finally(() => this.loading = false)
        }
      })
    },
    refund(packetId) {
      this.$swal.fire({
        title: this.$t('base.sure'),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('base.cancel'),
        confirmButtonText: this.$t('base.confirm')
      }).then((result) => {
        if (result.value) {
          this.loading = true
          packetService.refund(packetId).then(res => {
            this.$swal(this.$t('base.success'), this.$t('base.done'), 'success')
            EventBus.$emit("REFUND")
            this.getUserPackets()
          }).catch(error => {
            this.$swal.fire(this.$t('errors.error'), this.$t(`errors.${error.response.data.code}`), 'error')
          }).finally(() => this.loading = false)
        }
      })
    },
    getUserPackets() {
      this.loading = true
      packetService.getUserPackets(this.accountId)
          .then(res => {
            this.packets = res.data
            this.$emit('loadDone', res.data)
          })
          .finally(() => this.loading = false)
    },
    getPriceList(accountId) {
      this.loading = true
      Promise.all([packetService.getUserPriceList(accountId)])
          .then(res => {
            const [pricelist] = res
            this.pricelistPackets = pricelist.data.filter(p => !p.subscription.availableFree)
          })
          .catch((error) => {
            const status = error.response.data.status
            if (status === 555) {
              const code = error.response.data.code
              this.$swal(this.$t('errors.error'), this.$t(`errors.${code}`), 'error')
            }
          })
          .finally(() => {
            this.loading = false
          })
    }
  },
}
</script>

<style scoped>

</style>