<template>
  <div>
    <textarea id="clipboard-area" class="d-none"></textarea>
    <v-sheet elevation="10" dark outlined color="pink" class="text-center mb-1 font-weight-bold">
      http://epg.propg.net/epg.xml.gz <v-btn @click="copyToClipboard('http://epg.propg.net/epg.xml.gz')" icon small><v-icon small>mdi-content-copy</v-icon></v-btn>
    </v-sheet>
    <div class="card-items">
      <div class="card-item" v-for="p in list">
        <v-card  height="100%" class="mb-1" elevation="10">
          <v-card-text class="text-center">
            <v-row dense align-content="center" align="center">
              <div style="width: 50px">
                <v-img  width="50" height="50" contain :src="p.image"/>
              </div>
              <strong class="black--text title text-uppercase mr-2">{{p.name}}</strong>
            </v-row>

            <div class="mt-2">
              <v-menu offset-y v-if="!p.variants || p.variants.length === 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small dark rounded color="pink"
                           v-bind="attrs"
                           v-on="on">Playlist
                      <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item link @click="() => {overlay.text = p.link ; overlay.show = true}">
                      <v-list-item-title>{{ $t('iptv.show_link') }}</v-list-item-title>
                      <v-list-item-icon>
                        <v-icon small>mdi-eye</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                    <v-list-item link :href="p.link">
                      <v-list-item-title>{{ $t('iptv.download_file') }}</v-list-item-title>
                      <v-list-item-icon>
                        <v-icon small>mdi-download</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                    <v-list-item ripple link @click="copyToClipboard(p.link)">
                      <v-list-item-title>{{ $t('iptv.copy_link') }}</v-list-item-title>
                      <v-list-item-icon>
                        <v-icon small>mdi-content-copy</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-menu>
              <div v-else>
                <v-menu offset-y v-for="v in p.variants">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small dark rounded color="pink"
                           class="ml-1"
                           v-bind="attrs"
                           v-on="on">{{ v.name }}
                      <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item ripple link @click="() => {overlay.text = v.link ; overlay.show = true}">
                      <v-list-item-title>{{ $t('iptv.show_link') }}</v-list-item-title>
                      <v-list-item-icon>
                        <v-icon small>mdi-eye</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                    <v-list-item link :href="v.link">
                      <v-list-item-title>{{ $t('iptv.download_file') }}</v-list-item-title>
                      <v-list-item-icon>
                        <v-icon small>mdi-download</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                    <v-list-item ripple link @click="copyToClipboard(v.link)">
                      <v-list-item-title>{{ $t('iptv.copy_link') }}</v-list-item-title>
                      <v-list-item-icon>
                        <v-icon small>mdi-content-copy</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>

            <div class="caption mt-2">{{p.description}}</div>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <v-overlay opacity="1" :value="overlay.show">
      <v-row justify="center" class="">
        <div class="text-center pa-6">
          <div class="text-lg-h3 text-md-h5 text-sm-h5" style="overflow-wrap: anywhere;">{{ overlay.text }}</div>
          <v-btn class="mt-2" outlined @click="overlay.show = false">
            <v-icon left>mdi-close</v-icon>
            {{ $t('base.close') }}
          </v-btn>
        </div>
      </v-row>
    </v-overlay>
    <v-snackbar v-model="snack">{{ $t('messages.copied') }}</v-snackbar>
  </div>
</template>

<script>
import iptvService from "../../service/api/iptvService";

export default {
  name: "Playlists",
  props: {
    id: {
      type: String,
    },
  },
  computed: {
    list() {
      const st = this.st
      if (!st || st === 'all') return this.pl
      return this.pl.filter(p => p.tags.includes(st))
    }
  },
  data() {
    return {
      overlay: {
        text: "",
        show: false,
      },
      st: '',
      pl: [],
      snack: false,
      tags: [],
    }
  },
  created() {
    this.getTags()
    this.getPlaylists(this.id)
  },
  methods: {
    getTags() {
      iptvService.getTags().then(res => this.tags = res.data)
    },
    getPlaylists() {
      iptvService.getPlaylists(this.id).then(res => this.pl = res.data)
    },
    copyToClipboard(str) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(str).then(() => {
          this.snack = true
        })
        return
      }
    }
  },
}
</script>

<style scoped>
.card-items {
  display: flex;
  flex-wrap: wrap;
  gap: 3px
}

.card-item {
  width: 270px;
  align-self: stretch;
  flex:1;
}
</style>