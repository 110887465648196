<template>
  <v-card elevation="12">
    <v-card-title>
      {{$t('base.subs')}}
    </v-card-title>
    <v-card-text>
     <v-row>
       <v-col cols="6" sm="4">
         <div class="text-center">{{$t('packet.active')}}</div>
         <div class="display-2 text-center" :class="{'green--text' : stats.active > 0}">
           {{stats.active}}
         </div>
       </v-col>
       <v-col cols="6" sm="4">
         <div class="text-center">{{$t('packet.soon_expired')}}</div>
         <div class="display-2 text-center" :class="{'red--text' : stats.expired > 0}">
           {{stats.expired}}
         </div>
       </v-col>
       <v-col cols="12" sm="4" >
         <v-btn class="mb-1" to="/iptv" small block color="pink">{{$t('base.pay')}} IPTV</v-btn>
<!--         <v-btn small block to="/subscriptions" color="pink">{{$t('base.pay')}} CardShare</v-btn>-->
       </v-col>
     </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import UserService from "@/service/api/userService";

export default {
  name: "SubscriptionsOverviewCard",
  created() {
    this.loadStats()
  },
  data() {
    return {
      loading: false,
      stats : {}
    }
  },
  methods: {
    loadStats() {
      this.loading = true
      UserService.getSubscriptionsStat().then(res => this.stats = res.data).finally(() => this.loading = false)
    }
  },
}
</script>

<style scoped>

</style>