import Home from '@/views/Home'
import Login from '@/views/auth/Login'
import Register from "@/views/auth/Register";
import NotFound from "../views/NotFound";
import Dashboard from "../components/layout/Dashboard";
import Settings from "../views/Settings";
import Help from "../views/Help";
import Transactions from "../views/Transactions";
import HistoryLog from "../views/HistoryLog";
import BalancePurchase from "../views/BalancePurchase";
import User from "../views/User";
import AccountPanel from "../components/account/AccountPanel";
import Main from "../views/Main";
import Forgot from "../views/auth/Forgot";
import Cardshare from "../views/Cardshare";
import Iptv from "../views/Iptv";
import Faq from "../views/Faq";
import Payment from "@/views/Payment";
import SuccessPayment from "@/components/payment/SuccessPayment";
import ErrorPayment from "@/components/payment/ErrorPayment";
import Revover from "@/views/auth/Revover";
import Confirm from "@/views/auth/Confirm";


const menu = [
    {
        path: '/overview',
        name: 'Home',
        component: Home,
        meta : {
            icon : "mdi-clipboard-outline"
        }
    },
    {
        path: '/iptv',
        name: 'Iptv',
        component : Iptv,
        meta : {
            icon : "mdi-wifi",
            hide : false,
            colored : true
        },
    },
    // {
    //     path: '/subscriptions',
    //     name: 'Subscriptions',
    //     component : Cardshare,
    //     meta : {
    //         icon : "mdi-satellite-variant"
    //     },
    // },
    // {
    //     path: '/dealer',
    //     name: 'Dealer',
    //     component : Dealer,
    //     meta : {
    //         icon : "mdi-account-multiple"
    //     }
    // },
    {
        path: '/transactions',
        name: 'Transactions',
        component : Transactions,
        meta : {
            icon : "mdi-currency-eur"
        }
    },
    {
        path: '/history',
        name: 'History',
        component : HistoryLog,
        meta : {
            icon : "mdi-history"
        }
    },
    {
        path: '/settings',
        name: 'Settings',
        component : Settings,
        meta : {
            icon : "mdi-settings"
        }
    },
    // {
    //     path: '/faq',
    //     name: 'Faq',
    //     component : Faq,
    //     meta : {
    //         icon : "mdi-help-circle-outline"
    //     }
    // },
    // {
    //     path: '/help',
    //     name: 'Help',
    //     component : Help,
    //     meta : {
    //         icon : "mdi-help"
    //     }
    // },
]

const dashboardService = [
    {
        path: '/balance',
        name: 'BalancePurchase',
        component: BalancePurchase,
        meta : {
            icon : "mdi-clipboard-outline"
        }
    },
    {
        path: '/payment',
        name: 'Payment',
        component: Payment,
        children : [
            {
                path : 'success',
                name : 'SuccessPayment',
                component : SuccessPayment,
                meta : {
                    icon : 'mdi-account-card-details'
                }
            },
            {
                path : 'error',
                name : 'ErrorPayment',
                component : ErrorPayment,
                meta : {
                    icon : 'mdi-account-card-details'
                }
            },
        ],
        meta : {
            icon : "mdi-clipboard-outline"
        }
    },
    // {
    //     path: '/user/:id',
    //     name: 'User',
    //     component: User,
    //     redirect : '/user/:id/info',
    //     children : [
    //         {
    //             path : 'info',
    //             name : 'UserInfo',
    //             component : UserInfo,
    //             meta : {
    //                 icon : 'mdi-account-card-details'
    //             }
    //         },
    //         {
    //             path : 'packets',
    //             name : 'UserPackets',
    //             component : UserPackets,
    //             meta : {
    //                 icon : 'mdi-monitor'
    //             }
    //         },
    //         {
    //             path : 'log',
    //             name : 'UserLog',
    //             component : UserLog,
    //             meta : {
    //                 icon : 'mdi-format-list-bulleted'
    //             }
    //         },
    //         {
    //             path : 'transactions',
    //             name : 'UserTransactions',
    //             component : UserTransactions,
    //             meta : {
    //                 icon : 'mdi-currency-eur'
    //             }
    //         },
    //
    //     ]
    // },
]

const dashboard = {
    path : "/d",
    name : "Dashboard",
    component : Dashboard,
    redirect : '/overview',
    children : menu.concat(dashboardService)
}


const service = [
    {   path: '/',
        name : "Welcome",
        component : Main,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    {
        path: '/forgot',
        name: 'ForgotPassword',
        component: Forgot
    },
    {
        path: "/password_recover",
        name : "Recover",
        component: Revover,
    },
    {
        path: "/confirm",
        name : "Confirm",
        component: Confirm,
    },
    {
        path : '*',
        component : NotFound
    }
]

const routes = [dashboard].concat(service)

export  {routes, menu}