<template>
  <div>
    <v-toolbar dense class="mb-1" dark>
      <v-toolbar-title class="text-uppercase">{{ $t('base.info') }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn color="pink lighten-3" text @click="saveAccount">
          <v-icon>mdi-content-save</v-icon>
          {{ $t('base.save') }}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-form ref="form">
      <v-list>
        <v-list-item>
          <v-list-item-title class="font-weight-bold">{{ $t('base.label') }}</v-list-item-title>
          <v-list-item-subtitle>
            <v-text-field
                filled
                dense
                v-model="account.label"
                :rules="rules.label"
                single-line
                counter
            ></v-text-field>
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="font-weight-bold">{{ $t('base.login') }}</v-list-item-title>
          <v-list-item-subtitle class="font-weight-bold orange--text">{{ account.login }}</v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="font-weight-bold">{{ $t('base.password') }}</v-list-item-title>
          <v-list-item-subtitle v-if="account.provider === 'CS'">
            <v-text-field
                filled
                dense
                v-model="account.password"
                :rules="rules.password"
                single-line
                counter
            ></v-text-field>
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else class="font-weight-bold orange--text">{{ account.password }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="font-weight-bold">{{ $t('base.parent_code') }}</v-list-item-title>
          <v-list-item-subtitle>
            <v-text-field
                filled
                dense
                v-model="account.parentalPassword"
                :rules="rules.ppass"
                single-line
                counter
            ></v-text-field>
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item v-if="account.provider === 'CS'">
          <v-list-item-title class="font-weight-bold">{{ $t('base.server') }}</v-list-item-title>
          <v-list-item-subtitle>
            <server-select v-model="account.server"/>
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="font-weight-bold">{{ $t('users.auto_continue') }}</v-list-item-title>
          <v-list-item-subtitle>
            <v-switch color="pink" v-model="account.autoContinue"/>
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-form>
    <v-textarea :label="$t('base.note')" outlined filled v-model="account.note">
    </v-textarea>
    <span class="caption"
          v-if="account.updateDateTime">{{ $t('accounts.edited') }} :  {{ account.updateDateTime }}</span>
  </div>
</template>

<script>
import AccountService from "../../service/api/accountService";
import ServerSelect from "../chooser/ServerSelect";

export default {
  name: "AccountInfo",
  components: {ServerSelect},
  props: {
    account: {
      type: Object,
    },
  },
  data() {
    return {
      savingLoad: false,
      rules: {
        label: [v => !!v || this.$t('errors.empty')],
        password: [
          v => !!v || this.$t('errors.empty'),
          v => v.length > 5 && v.length < 15 || this.$t('errors.size', {min: 6, max: 15}),
        ],
        ppass: [
          v => !!v || this.$t('errors.empty'),
          v => v.length >= 4 && v.length <= 20 || this.$t('errors.size', {min: 4, max: 20}),
        ],
      },
    }
  },
  methods: {
    getConfig() {
      AccountService.getConfig(this.account.id).then(res => {
        this.account.note = res.data
      })
    },
    saveAccount() {
      if (!this.$refs.form.validate()) return
      this.savingLoad = true
      AccountService.save(this.account)
          .then(res => {
            this.$emit('success')
            this.$swal(this.$t('base.success'), '', 'success')
          })
          .catch(() => this.$swal(this.$t('errors.error'), '', 'error'))
          .finally(() => this.savingLoad = false)
    }
  },
}
</script>

<style scoped>
</style>