<template>
    <v-container fluid >
      <account-list :type="type"/>
    </v-container>
</template>
            <script>
                import UserPackets from "../packet/UserPackets";
                import AccountInfo from "../account/AccountInfo";
                import AccountService from "../../service/api/accountService";
                import AccountPanel from "@/components/account/AccountPanel";
                import AccountList from "@/components/account/AccountList";
                export default {
                    name: "user-accounts",
                    components: {AccountList, AccountPanel, AccountInfo, UserPackets},
                    props: {
                      type : {
                        type : String,
                        default : "CS",
                      }
                    },
                    created() {
                        //this.getAccounts()
                    },
                    data() {
                        return {
                          dr : false,
                          account : null,
                        }
                    },
                    methods: {
                      createAccount(){
                        AccountService.create(this.type).then(res => {
                          this.$swal(this.$t('base.success'), '', 'success')
                          this.$refs.at.getAccounts()
                        }).catch((err) => this.$swal(this.$t(`errors.error`),'','error'))
                      },
                      showAccount(a){
                        this.dr = false
                        this.account = a
                      },
                    }
                }
            </script>

            <style scoped>

            </style>