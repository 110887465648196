<template>
  <div>
    <div>
      <v-row>
        <v-col cols="12" sm="6" md="4" v-for="p in filteredPackets">
          <user-price-item @onBuy="$emit('onBuy')" :account-id="accountId" :item="p"/>
        </v-col>
      </v-row>
    </div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"/>
    </v-overlay>
  </div>
</template>

<script>
import UserPriceItem from "./UserPriceItemEx";
import packetService from "../../service/api/packetService";
import serverService from "../../service/api/serverService";

export default {
  name: "user-price-list",
  components: {UserPriceItem},
  props: {
    accountId: {
      type: String,
    },
    filter: {
      type: String,
      default: ""
    }
  },
  created() {
    this.getPriceList(this.accountId)
  },
  computed: {
    filteredPackets() {
      let packets = this.packets
      const filter = this.filter ? this.filter.trim() : ""
      if (filter)
        packets = packets.filter(p => p.subscription.name.toUpperCase().includes(filter.toUpperCase()))
      return packets
    },
  },
  data() {
    return {
      loading: false,
      packets: []
    }
  },
  methods: {
    getPriceList(accountId) {
      this.loading = true
      Promise.all([packetService.getUserPriceList(accountId)])
          .then(res => {
            const [pricelist] = res
            this.packets = pricelist.data
          })
          .catch((error) => {
            const status = error.response.data.status
            if (status === 555) {
              const code = error.response.data.code
              this.$swal(this.$t('errors.error'), this.$t(`errors.${code}`), 'error')
            }
          })
          .finally(() => {
            this.loading = false
          })
    }
  },
}
</script>

<style scoped>

</style>