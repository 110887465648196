<template>
  <div>
    <v-toolbar dense>
      <v-toolbar-title>
        <v-icon>mdi-account</v-icon>
        {{$t('accounts.accounts')}}
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn :icon="$vuetify.breakpoint.mobile" @click="createAccount" text color="orange"><span v-if="!$vuetify.breakpoint.mobile">{{$t('accounts.create')}}</span>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-text-field prepend-inner-icon="mdi-magnify" clearable dense solo-inverted v-model="filter" :label="$t('base.search')" />
    <v-pagination
        v-if="total > options.itemsPerPage"
        v-model="options.page"
        :length="Math.ceil(total / options.itemsPerPage)"
        circle
    ></v-pagination>
    <v-row>
      <v-col cols="12" md="4" v-for="a in accounts">
<!--        TODO to mixin-->
        <component :is="a.account.provider === 'IPTV' ? IptvAccount : CSAccount" :account="a" @openModal="data => openModal(data.name, data.account)" @remove="id => removeAccount(id)" />
      </v-col>
    </v-row>
    <v-pagination
        v-if="total > options.itemsPerPage"
        v-model="options.page"
        :length="Math.ceil(total / options.itemsPerPage)"
        circle
    ></v-pagination>
    <v-dialog v-model="dialogs.info" persistent fullscreen>
      <div v-if="viewedAccount" >
        <v-card>
          <v-card-title>
            <div class="title text-center">{{viewedAccount.label}}</div>
            <v-spacer />
            <v-btn @click="dialogs.info = false" icon><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <account-panel :key="viewedAccount" @modify="getAccounts" :account="viewedAccount" />
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>
    <v-dialog v-model="dialogs.subs" persistent overlay-opacity="1" max-width="1000">
      <div v-if="viewedAccount" >
        <v-toolbar  tile dense flat color="blue-grey darken-4">
          <v-toolbar-title>{{viewedAccount.label}} [{{viewedAccount.login}}]</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn @click="dialogs.subs = false" icon><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card>
          <v-card-text>
            <user-packets @refresh="getAccounts" :key="viewedAccount"  :account="viewedAccount" />
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import AccountService from "@/service/api/accountService";
import AccountPanel from "@/components/account/AccountPanel";
import UserPackets from "../packet/UserPackets";
import IptvAccount from "@/components/account/widget/IptvAccount.vue";
import CSAccount from "@/components/account/widget/CSAccount.vue";

export default {
  name: "account-list",
  computed: {
    CSAccount() {
      return CSAccount
    },
    IptvAccount() {
      return IptvAccount
    }
  },
  components : {
    UserPackets,
    AccountPanel,
  },
  props: {
    type : {
      type : String
    }
  },
  data() {
    return {
      viewedAccount : null,
      total: 0,
      dialogs : {
        info :  false,
        subs : false,
      },
      accounts : [],
      loading : false,
      selected : 0,
      options : {
        page : 1,
        itemsPerPage : 9,
      },
      filter : '',
    }
  },
  watch: {
    filter : {
      handler(){
        this.options.page = 1
        this.getAccounts()
      },
    },
    options : {
      deep : true,
      handler() {
        this.getAccounts()
      }
    }
  },
  created() {
    this.getAccounts()
  },
  methods: {
    openModal(t,a){
      this.viewedAccount = a
      if (t === 'info')
        this.dialogs.info = true

      if (t === 'subs')
        this.dialogs.subs = true

    },
    getAccounts() {
      this.loading = true
      AccountService.getAccounts(this.options, this.type,this.filter).then(res => {
        res.data.content.sort((a,b) => a.packetState.active < b.packetState.active ? 1 : -1)
        this.accounts = res.data.content
        this.total = res.data.totalElements
        this.$emit('selectAccount', this.accounts[0].account)
       // this.panelsState =  this.accounts.length === 1  ? 0 : -1
      }).finally(() => this.loading = false)
    },
    packetColor(i){
      return i.active > 0 ? 'green--text' : 'red--text'
    },
    createAccount() {
      AccountService.create(this.type).then(res => {
        this.$swal(this.$t('base.success'), '', 'success')
        this.options.page = 1
        this.getAccounts()
      })
    },
    removeAccount(id){
        this.$swal.fire({
            title: this.$t('base.sure'),
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: this.$t('base.cancel'),
            confirmButtonText: this.$t('base.confirm')
        }).then((result) => {
            if (result.value) {
                this.loading = true
                AccountService.remove(id).then(res => {
                    this.$swal(this.$t('base.success'), this.$t('base.done'), 'success')
                    this.getAccounts()
                }).catch(error => {
                    this.$swal.fire(this.$t('errors.error'), this.$t(`errors.${error.response.data.error}`), 'error')
                }).finally(() => this.loading = false)
            }
        })
    }
  },
}
</script>

<style scoped>

</style>