<script>

export default {
  name: "BaseAccount",
  props: {
    account: {
      type: Object,
    },
  },
  computed: {
  },
}
</script>

<template>
  <v-card>
    <v-card-title>{{account.account.label}}
    <v-spacer />
      <v-btn color="red" @click="$emit('remove', account.account.id)" icon depressed rounded>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-alert color="success" dense  type="info" v-if="account.account.autoContinue && account.packetState.active">{{$t('packet.autoContinueEnabled', {days : 3})}}</v-alert>
      <slot />
      <v-row v-if="!account.packetState.active" no-gutters>
        <v-img contain width="120" height="120" src="@/assets/sad_face.png" />
        <div class="text-h5 text-center">{{$t('packet.no_subscriptions')}}</div>
      </v-row>
      <v-row :justify="$vuetify.breakpoint.md ? 'space-between' : 'center'" no-gutters class="mt-2">
        <v-btn @click="$emit('openModal', {name : 'info', account : account.account})"
               :block="$vuetify.breakpoint.md || $vuetify.breakpoint.mobile" outlined>{{$t('menu.Settings')}}
          <v-icon>mdi-settings</v-icon>
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped>

</style>