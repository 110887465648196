<template>
    <v-row>
      <v-col cols="12" md="8">
        <v-card light>
          <v-card-text>
            <v-toolbar dense dark>
              <v-toolbar-title class="text-uppercase">{{$t('base.subs')}}</v-toolbar-title>
              <v-spacer/>
            </v-toolbar>
            <v-sheet>
              <user-packets  @refresh="refresh" :account="account"/>
            </v-sheet>
          </v-card-text>
        </v-card>

      </v-col>
        <v-col cols="12" md="4">
          <v-card elevation="12" light>
            <v-card-text>
              <account-info @success="refresh" :account="account" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card elevation="12" light>
            <v-card-text>
            <v-toolbar dark dense class="mb-2">
                <v-toolbar-title>{{$t('base.log')}}</v-toolbar-title>
            </v-toolbar>
            <account-history class="v-step-2" :id="account.id" />
            </v-card-text>
          </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import UserPackets from "../packet/UserPackets";
    import AccountInfo from "./AccountInfo";
    import ConfigInstruction from "./ConfigInstruction";
    import AccountHistory from "./AccountHistory";
    export default {
        name: "AccountPanel",
        components : {AccountHistory, UserPackets,AccountInfo,ConfigInstruction},
        props: {
            account: {
                type: Object
            },
        },
      mounted(){

      },
      data() {
        return {
        }
      },
      methods: {
        refresh() {
          this.$emit('modify')
          this.$refs.cfgs.load()
        }
      },
    }
</script>

<style scoped>
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px yellow;
}
</style>