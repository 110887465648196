<script>
import SubscriptionWidget from "@/components/packet/SubscriptionWidget.vue";
import BaseAccount from "@/components/account/widget/BaseAccounItem.vue";

export default {
  name: "IptvAccount",
  components: {BaseAccount, SubscriptionWidget},
  props: {
    account: {
      type: Object,
    },
  },
  computed: {},
}
</script>

<template>
  <base-account :account="account" @openModal="data => $emit('openModal', data)"
                @remove="data => $emit('remove', data)">
    <template v-slot>
      <subscription-widget light v-if="account.subscriptions.length" :packet="account.subscriptions[0]"/>
    </template>
  </base-account>
</template>

<style scoped>

</style>